.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #2875A5;
  height: 120px;
  padding: 20px;
  color: white;
}

.App-footer {
  background-color: rgb(255, 255, 255);
  height: 150px;
  padding: 20px;
  color: black;
}

.App-content {
  background-color: rgb(255, 255, 255);
  min-height: 500px;
  padding: 20px;
  color: black;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Global Button */
.button {
  backgroundColor: white;
  font: inherit;
  border: 1px solid blue;
  padding: 8px;
  cursor: pointer
}